import React, { useEffect } from 'react';
import clapSound from '../assets/clap.mp3';
import clapGif from '../assets/clap.gif';
//import starsGif from './assets/stars.gif';
import './ApplauseOverlay.css';

const ApplauseOverlay = ({ applaudType }) => {
  useEffect(() => {
    // Play the audio when the component is mounted
    const audio = new Audio(clapSound);
    audio.play();

    // Stop the audio when the component unmounts
    return () => {
      audio.pause();
      audio.currentTime = 0; // Reset playback position
    };
  }, []);

  // Select the corresponding GIF based on the applaud type
  const gifMap = {
    clap: clapGif,
    //stars: starsGif,
  };

  return (
    <div className="applause-overlay">
      <img src={gifMap[applaudType]} alt="Applause" className="applause-gif" />
    </div>
  );
};

export default ApplauseOverlay;
